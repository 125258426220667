import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  BusinessRounded,
  CalendarTodayRounded,
  ErrorRounded,
  Facebook,
  HelpOutlineRounded,
  LinkedIn,
  LocationOnRounded,
  ScheduleRounded,
  SpeedRounded,
  SupervisedUserCircleRounded,
  Twitter,
} from '@material-ui/icons';
import { DateTime } from 'luxon';
import humanizeDuration from 'humanize-duration';
import { EVENT_ALIAS, GATHERLY_LOGO_URL } from '../../config';
import { Button } from '../../components/Button';
import { Clickable } from '../../components/Clickable';
import { IS_BROWSER_SUPPORTED, IS_DEVICE_SUPPORTED } from '../../utils/device';
import { c, DEFAULT_BANNER_URL, formatDate, getEventStatus } from '../../utils';
import { EventStatus } from '../../types/eventStatus';
import classes from './LandingPage.module.scss';
import { AugmentedBetterDmvEvent, SECOND } from '@poormanvr/common';

interface Props {
  eventInfo: AugmentedBetterDmvEvent;
}

const eventHostName = `${EVENT_ALIAS}.event.gatherly.io`;
const eventUrl = `https://${eventHostName}/`;
const encodedEventUrl = window.encodeURIComponent(eventUrl);

export function LandingPage({ eventInfo }: Props) {
  const {
    startTime,
    stopTime,
    capacity,
    name,
    hostCompany,
    hostEmail,
    config: {
      landingPage: { description, logoUrls, bannerUrl },
    },
  } = eventInfo;

  const start = useMemo(() => DateTime.fromMillis(startTime), [startTime]);
  const end = useMemo(() => DateTime.fromMillis(stopTime), [stopTime]);

  const formattedStart = useMemo(
    () => start.toLocaleString(DateTime.DATE_HUGE),
    [start],
  );

  const formattedEnd = useMemo(() => {
    const singleDay =
      start.startOf('day').toMillis() === end.startOf('day').toMillis();

    return `${start.toLocaleString(
      DateTime.TIME_SIMPLE,
    )} to ${end.toLocaleString({
      ...DateTime.DATETIME_FULL,
      year: start.year === end.year ? undefined : 'numeric',
      month: singleDay ? undefined : 'long',
      day: singleDay ? undefined : 'numeric',
    })}`;
  }, [start, end]);

  const timezone = useMemo(() => start.zoneName, [start]);

  // reload the page 3 seconds after the countdown hits 0, to prevent infinite reload
  const ticksUntilReload = useRef(3);
  const [timestamp, setTimestamp] = useState(Date.now());

  useEffect(() => {
    const handle = window.setInterval(() => {
      setTimestamp(Date.now());
    }, SECOND);
    return () => {
      window.clearInterval(handle);
    };
  }, []);

  const eventStatus = useMemo(
    () => getEventStatus(startTime, stopTime, timestamp),
    [startTime, stopTime, timestamp],
  );

  const formattedCountdown = useMemo(() => {
    switch (eventStatus) {
      case EventStatus.FUTURE:
        return `This event will start in ${humanizeDuration(
          startTime - timestamp,
          { largest: 1, round: true },
        )}.`;
      case EventStatus.CURRENT:
        if (ticksUntilReload.current-- === 0) {
          window.location.reload();
        }
        return 'This event is starting now.';
      case EventStatus.PAST:
        return 'This event has ended.';
    }
  }, [eventStatus, startTime, timestamp]);

  return (
    <div className={classes.LandingPage}>
      <div className={classes.pageWrapper}>
        <header className={classes.header}>
          <div className={classes.headerWrapper}>
            <Clickable href="https://gatherly.io/">
              <img
                src={GATHERLY_LOGO_URL}
                alt="Gatherly logo"
                className={classes.headerLogo}
              />
            </Clickable>
            <Button
              className={classes.help}
              href="https://help.gatherly.io/en/articles/4663287-what-is-gatherly"
              icon={HelpOutlineRounded}
            >
              What's Gatherly?
            </Button>
          </div>
        </header>
        <div className={classes.body}>
          <div className={classes.background} />
          <div className={classes.content}>
            <div
              className={classes.cover}
              style={{
                backgroundImage: `url(${bannerUrl || DEFAULT_BANNER_URL})`,
              }}
            />
            {(!IS_BROWSER_SUPPORTED || !IS_DEVICE_SUPPORTED) && (
              <div className={classes.warning}>
                <ErrorRounded className={classes.icon} />
                <p className={classes.message}>
                  {!IS_DEVICE_SUPPORTED
                    ? "This device isn't supported yet. Please join on a laptop or desktop computer."
                    : "This browser isn't supported yet. Please join with Chrome or Firefox."}
                </p>
                <Clickable
                  inline
                  href="https://help.gatherly.io/en/articles/4665224-technical-specifications-for-gatherly"
                  className={classes.learnMore}
                >
                  Learn More
                </Clickable>
              </div>
            )}
            <div className={classes.info}>
              <div className={classes.summary}>
                <h3>{name}</h3>
                <div className={classes.item}>
                  <div className={classes.iconWrapper}>
                    <ScheduleRounded className={classes.icon} />
                  </div>
                  <div className={classes.text}>
                    <div className={classes.primary}>{formattedStart}</div>
                    <div className={classes.secondary}>{formattedEnd}</div>
                  </div>
                </div>
                <Clickable className={classes.item} href={eventUrl}>
                  <div className={classes.iconWrapper}>
                    <LocationOnRounded className={classes.icon} />
                  </div>
                  <div className={classes.text}>
                    <div className={classes.primary}>Virtual on Gatherly</div>
                    <div className={classes.secondary}>{eventHostName}</div>
                  </div>
                </Clickable>
                <h4>Hosted by</h4>
                <Clickable
                  className={classes.item}
                  href={`mailto:${hostEmail}`}
                >
                  <div className={classes.iconWrapper}>
                    <BusinessRounded className={classes.icon} />
                  </div>
                  {hostCompany ? (
                    <div className={classes.text}>
                      <div className={classes.primary}>{hostCompany}</div>
                      <div className={classes.secondary}>{hostEmail}</div>
                    </div>
                  ) : (
                    <div className={classes.text}>
                      <div className={classes.primary}>{hostEmail}</div>
                    </div>
                  )}
                </Clickable>
                {logoUrls.length > 0 && (
                  <>
                    <h4>Sponsored by</h4>
                    <div className={classes.sponsors}>
                      {logoUrls.map((logoUrl, i) => (
                        <img
                          alt={`Sponsor logo ${i + 1}`}
                          key={i}
                          className={classes.sponsor}
                          src={logoUrl}
                        />
                      ))}
                    </div>
                  </>
                )}
              </div>
              <div className={classes.detail}>
                <h4>{formattedCountdown}</h4>
                <div className={classes.actions}>
                  <div
                    className={c(classes.addToCalendar, 'addeventatc')}
                    role="button"
                  >
                    <Button
                      primary={eventStatus === EventStatus.FUTURE}
                      className={classes.fakeButton}
                      icon={CalendarTodayRounded}
                      tabIndex={undefined}
                      aria-hidden
                    >
                      Add to Calendar
                    </Button>
                    <span className="start">{formatDate(startTime)}</span>
                    <span className="end">{formatDate(stopTime)}</span>
                    <span className="timezone">{timezone}</span>
                    <span className="title">{name}</span>
                    <span className="description">{description}</span>
                    <span className="location">{eventUrl}</span>
                    <span className="addeventatc_icon atc_node notranslate" />
                  </div>
                  <Button href="https://check.gatherly.io/" icon={SpeedRounded}>
                    Check Connectivity
                  </Button>
                </div>
                <h4>Event Description</h4>
                {description && (
                  <p className={classes.description}>{description}</p>
                )}
                <div className={classes.capacity}>
                  <SupervisedUserCircleRounded className={classes.icon} />
                  <div className={classes.text}>
                    {capacity} expected attendees.{' '}
                  </div>
                </div>
                <h4>Share this Event</h4>
                <div className={classes.socialSharing}>
                  <Button
                    className={classes.shareButton}
                    href={`https://www.facebook.com/sharer/sharer.php?u=${encodedEventUrl}`}
                  >
                    <Facebook />
                  </Button>
                  <Button
                    className={classes.shareButton}
                    href={`https://twitter.com/intent/tweet?url=${encodedEventUrl}`}
                  >
                    <Twitter />
                  </Button>
                  <Button
                    className={classes.shareButton}
                    href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodedEventUrl}`}
                  >
                    <LinkedIn />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className={classes.footer}>
          <div className={classes.footerWrapper}>
            <Clickable href="https://gatherly.io/">
              <img
                src={GATHERLY_LOGO_URL}
                alt="Gatherly logo"
                className={classes.footerLogo}
              />
            </Clickable>
            <div className={classes.links}>
              <div className={classes.linkGroup}>
                <h5>About Us</h5>
                <p>
                  <Clickable inline href="https://www.gatherly.io/blog">
                    Blog
                  </Clickable>
                </p>
                <p>
                  <Clickable inline href="https://help.gatherly.io/">
                    Help Center
                  </Clickable>
                </p>
              </div>
              <div className={classes.linkGroup}>
                <h5>Contact Us</h5>
                <p>
                  <Clickable inline href="https://www.gatherly.io/feedback">
                    Feedback
                  </Clickable>
                </p>
                <p>
                  <Clickable inline href="https://gatherly.breezy.hr/">
                    Hiring
                  </Clickable>
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

import React, {
  createContext,
  Dispatch,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import MuiThemeProvider from '@material-ui/styles/ThemeProvider';
import { COLOR_ENGAGEMENT, COLOR_USER } from '../styles';

interface Value {
  theme: Theme;
  setTheme: Dispatch<Theme>;
}

export const ThemeContext = createContext<Value>(null as any);

interface Props {
  children: ReactNode;
}

export enum Theme {
  LIGHT,
  DARK,
  CONTRAST,
}

export function ThemeProvider({ children }: Props) {
  const [theme, setTheme] = useState(Theme.LIGHT);

  const value = useMemo<Value>(
    () => ({
      theme,
      setTheme,
    }),
    [theme, setTheme],
  );

  useEffect(() => {
    const rootElement = document.querySelector(':root');
    if (!rootElement) return;
    rootElement.classList.toggle('dark', theme === Theme.DARK);
    rootElement.classList.toggle('contrast', theme === Theme.CONTRAST);
  }, [theme]);

  const muiTheme = useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: theme === Theme.LIGHT ? 'light' : 'dark',
          primary: {
            main: COLOR_USER,
          },
          secondary: {
            main: COLOR_ENGAGEMENT,
          },
        },
      }),
    [theme],
  );

  return (
    <MuiThemeProvider theme={muiTheme}>
      <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
    </MuiThemeProvider>
  );
}

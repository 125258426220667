import { Dictionary, IBuilding, IFloor, IUser, Point } from '@poormanvr/common';

export enum TutorialCardDirection {
  TOP_LEFT = 'topLeft',
  TOP_RIGHT = 'topRight',
  BOTTOM_LEFT = 'bottomLeft',
  BOTTOM_RIGHT = 'bottomRight',
}

export interface TutorialElementKeyPoints {
  top: number;
  bottom: number;
  left: number;
  right: number;
  centerX: number;
  centerY: number;
}

export interface TutorialStep {
  selector: string;
  dataCy: string;
  text: string | ((state: TutorialState) => string);
  cardDirection: TutorialCardDirection;
  point?: (c: TutorialElementKeyPoints) => Point;
  done?: (state: TutorialState) => boolean;
  dependent?: true; // dependent on previous step
}

export interface TutorialState {
  building: IBuilding | null;
  floors: Dictionary<IFloor>;
  me: IUser;
  them: IUser;
  destinationFloor: IFloor | null;
  brochureOpened: boolean;
  elevatorOpened: boolean;
}

/* eslint-disable max-len */
export const STEPS: TutorialStep[] = [
  {
    selector: '#map',
    dataCy: 'tutorial-map-button',
    text:
      'This panel is the map. You are the dark gray diamond. Try moving by <b>clicking</b> on a different spot on the map!',
    cardDirection: TutorialCardDirection.BOTTOM_LEFT,
    point: ({ left, top }) => ({ x: left + 64, y: top + 96 }),
    done: ({ me }) => me.moving,
  },
  {
    selector: '#map',
    dataCy: 'tutorial-map-button-2',
    text:
      'Great! Now try clicking the light gray square to mimic joining our sample user in a test <b>huddle</b>!',
    cardDirection: TutorialCardDirection.BOTTOM_LEFT,
    point: ({ centerX, centerY }) => ({ x: centerX, y: centerY }),
    done: ({ me }) => Boolean(me.huddleId),
  },
  {
    selector: '#main-panel',
    dataCy: 'tutorial-video-container-button',
    text:
      'You did it! This space will be filled with <b>videos</b> of people in your group.',
    cardDirection: TutorialCardDirection.BOTTOM_RIGHT,
    point: ({ left, centerY }) => ({ x: left + 240, y: centerY }),
  },
  {
    selector: '#map',
    dataCy: 'tutorial-map-button-3',
    text:
      'Now try moving to the <b>elevators</b>. There, you can choose between floors.',
    cardDirection: TutorialCardDirection.TOP_LEFT,
    point: ({ left, bottom }) => ({ x: left + 16, y: bottom - 16 }),
    done: ({ elevatorOpened, destinationFloor }) =>
      !destinationFloor || elevatorOpened,
  },
  {
    selector: '#tab-container',
    dataCy: 'tutorial-side-panel-button',
    text: ({ destinationFloor }) =>
      `Awesome! All of the floors for your event will be accessible here. Now, let's join the <b>${
        destinationFloor?.name ?? ''
      }</b>!`,
    cardDirection: TutorialCardDirection.TOP_LEFT,
    point: ({ left, top }) => ({ x: left, y: top + 200 }),
    done: ({ destinationFloor, me }) =>
      !destinationFloor || destinationFloor.id === me.floorId,
    dependent: true,
  },
];
/* eslint-enable max-len */

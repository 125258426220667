export enum Type {
  TEST_TRACKER = 'test_tracker',
  GROUPPHOTO__INIT = 'GroupPhoto__init', // User clicks the button
  GROUPPHOTO__CANCEL = 'GroupPhoto__cancel', // Photographer cancels any time before photo taken
  GROUPPHOTO__SHARE = 'GroupPhoto__share', // Photographer downloads or shares
}
export interface Options
  extends Record<
    Type,
    { [key: string]: string | number | boolean } | undefined
  > {
  [Type.TEST_TRACKER]: {
    value: string;
  };
  [Type.GROUPPHOTO__INIT]: {
    huddleSize: number;
  };
  [Type.GROUPPHOTO__CANCEL]: undefined;
  [Type.GROUPPHOTO__SHARE]: {
    huddleSize: number;
    mode: string;
  };
}

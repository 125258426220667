import { EventEmitter } from 'events';
import { ISocket } from './Socket';
import {
  Action,
  diffJson,
  Snapshot,
  SocketClosingCode,
  Update,
} from '@poormanvr/common';

export class MockSocket extends EventEmitter implements ISocket {
  readonly server = new EventEmitter();
  private lastSnapshot?: Snapshot;
  private snapshotIndex = 0;

  public get isConnecting() {
    return false;
  }

  public get isOpen() {
    return true;
  }

  send<T extends Action.Type>(type: T, payload: Action.Mapping[T]): void {
    const event = Action.Type[type];
    this.server.emit(event, payload);
  }

  receive<T extends Update.Type>(type: T, payload: Update.Mapping[T]): void {
    const event = Update.Type[type];
    this.emit(event, payload);
  }

  receiveSnapshot(snapshot: Snapshot) {
    if (this.lastSnapshot) {
      this.receive(Update.Type.SNAPSHOT_DIFF, {
        snapshotDiff: diffJson(this.lastSnapshot, snapshot),
        snapshotIndex: this.snapshotIndex,
      });
    } else {
      this.receive(Update.Type.SNAPSHOT, {
        rawSnapshot: JSON.stringify(snapshot),
        snapshotIndex: this.snapshotIndex,
      });
    }
    this.lastSnapshot = snapshot;
    this.snapshotIndex++;
  }

  open(): void {
    this.emit('open');
  }

  close(code: SocketClosingCode): void {
    this.emit('close', code);
  }
}

export enum BroadcastScope {
  EVENT,
  FLOOR,
}

export enum StartingSpeakerType {
  ME,
  HUDDLE,
}

export enum CountdownOption {
  TEN_SECONDS,
  THIRTY_SECONDS,
  CUSTOM,
}

export interface IBroadcast {
  id: string;
  countdownTime: number;
  startingAt: number;
  floorId: string | null; // null means event-wide
  hostId: string;
  speakerIds: string[];
  scope: BroadcastScope;
  streamProviderType: StreamProviderType;
  externalBroadcastType: BroadcastType | null;
  externalBroadcastKey: string | null;
}

export enum BroadcastRole {
  HOST = 'host',
  AUDIENCE = 'audience',
}

export enum StreamProviderType {
  GATHERLY = 'gatherly',
  EXTERNAL = 'external',
}

export enum BroadcastType {
  YOUTUBE = 'youtube',
  VIMEO = 'vimeo',
  TWITCH = 'twitch',
  EXTERNAL_LINK = 'external_link',
}

export interface AgoraSession {
  channel: string;
  role: BroadcastRole;
  userToken: string;
  contentToken: string | null;
}

import { v4 as uuid } from 'uuid';
import {
  VIDEO_IDEAL_ASPECT_RATIO,
  VIDEO_MAX_ASPECT_RATIO,
  GROUP_PHOTO_MIN_ASPECT_RATIO,
} from '../config';
import {
  COLOR_WHITE,
  COLOR_SHADE_20,
  COLOR_SHADE_80,
  COLOR_ENGAGEMENT,
} from '../styles';
import { Grid, toListMap } from '../utils';
import logoSvg from '../assets/logo_narrow.svg';
import {
  IGroupPhoto,
  GroupPhotoStatus,
  IFloor,
  IUser,
} from '@poormanvr/common';

interface TileCoordinates {
  source: HTMLVideoElement | undefined;
  sourceWidth: number;
  sourceHeight: number;
  x: number;
  y: number;
  width: number;
  height: number;
  userId: string;
}
interface IGetTileDimensions {
  contentHeight: number;
  contentWidth: number;
  count: number;
  gutterSize: number;
}

export const CANVASES = {
  GroupPhoto: 'GroupPhoto__GroupPhoto',
  Temp: 'GroupPhoto__TEMP',
};

// Source: https://blog.hootsuite.com/social-media-image-sizes-guide/
const DIMENSIONS: { [key: string]: { height: number; width: number } } = {
  LinkedIn: {
    height: 627,
    width: 1200,
  },
  Facebook: {
    height: 630,
    width: 1200,
  },
  Twitter: {
    height: 512,
    width: 1024,
  },
};

const GUTTER_SIZE = 16;
const MARGIN_SIZE = 32;
const LOGO_HEIGHT = 3 * GUTTER_SIZE;
const MAP_HEADER_HEIGHT = 2 * GUTTER_SIZE;
const MAP_HEADER_FONT = '14px Lato';
const NAMEPLATE_MIN_WIDTH = 3 * GUTTER_SIZE;
const NAMEPLATE_MAX_WIDTH = 16 * GUTTER_SIZE;
const NAMEPLATE_HEIGHT = 1.25 * GUTTER_SIZE;
const NAMEPLATE_ICON_SIZE = 0.5 * GUTTER_SIZE;
const NAMEPLATE_FONT = '11px Lato';

const maxContentHeight = Object.keys(DIMENSIONS).reduce(
  (value: number, key: string) => {
    if (!value) return DIMENSIONS[key].height;
    if (DIMENSIONS[key].height < value) return DIMENSIONS[key].height;
    return value;
  },
  0,
);
const maxContentWidth = Object.keys(DIMENSIONS).reduce(
  (value: number, key: string) => {
    if (!value) return DIMENSIONS[key].width;
    if (DIMENSIONS[key].width < value) return DIMENSIONS[key].width;
    return value;
  },
  0,
);

function drawLogo(ctx: CanvasRenderingContext2D, contentWidth: number) {
  return new Promise(resolve => {
    const logo = new Image();
    logo.crossOrigin = 'Anoynmous';
    logo.onload = () => {
      const scaleFactor = LOGO_HEIGHT / logo.height;
      const width = logo.width * scaleFactor;
      const height = logo.height * scaleFactor;
      const x = contentWidth - width - 2 * GUTTER_SIZE;
      const y = GUTTER_SIZE;
      ctx.drawImage(logo, x, y, width, height);
      resolve(true);
    };

    logo.src = logoSvg;
  });
}

function drawMap(
  ctx: CanvasRenderingContext2D,
  mapUrl: string,
  floorName: string | undefined,
  contentWidth: number,
  contentHeight: number,
  mapHeight: number,
  gutterSize: number,
) {
  try {
    return new Promise(resolve => {
      const map = new Image();

      map.onload = () => {
        const scaleFactor = mapHeight / map.height;
        const width = map.width * scaleFactor;
        const height = map.height * scaleFactor;
        const x = contentWidth - width - gutterSize;
        const y = contentHeight - height - gutterSize;

        ctx.drawImage(map, x, y, width, height);

        if (floorName) {
          const mapHeaderX = x;
          const mapHeaderY = y - MAP_HEADER_HEIGHT;
          ctx.fillStyle = COLOR_WHITE;
          ctx.strokeStyle = COLOR_SHADE_20;
          ctx.lineWidth = 1;
          ctx.strokeRect(mapHeaderX, mapHeaderY, width, MAP_HEADER_HEIGHT);

          ctx.fillStyle = COLOR_SHADE_80;
          ctx.font = MAP_HEADER_FONT;
          ctx.fillText(
            floorName,
            mapHeaderX + 0.5 * MARGIN_SIZE,
            mapHeaderY + 0.675 * MARGIN_SIZE,
            0.75 * width,
          );
        }

        resolve(true);
      };
      map.onerror = (error: string | Event) => {
        console.error('Error loading map:', error);
        resolve(false);
      };

      /*
       HMTL Canvas requires all externally loaded images for be downloaded
       via a valid CORS policy for it to permit that image to be exportable
       as a PNG. Adding this query string to the image url prevents the browse
       from trying to use a cached version of the image which may have been
       downloaded under less strict conditions
      */

      map.crossOrigin = 'crossorigin';
      map.src = `${mapUrl}?${uuid()}`;
    });
  } catch (e) {
    console.error('Error drawing map!', e);
  }
}

export function drawVideoTiles(
  ctx: CanvasRenderingContext2D,
  canvasHeight: number,
  contentWidth: number,
  contentHeight: number,
  users: IUser[],
  myId: string,
) {
  const videoNodes = document.querySelectorAll('video');
  if (!videoNodes || !videoNodes.length) {
    console.warn('No videos');
    return;
  }
  const videos = Array.from(videoNodes);

  const { columns, rows, tileHeight, tileWidth } = getTileDimensions({
    count: users.length,
    contentWidth,
    contentHeight,
    gutterSize: GUTTER_SIZE,
  });

  const tileCoordinates = getTileCoordinates({
    users,
    videos,
    tileWidth,
    tileHeight,
    rows,
    columns,
    gutterSize: GUTTER_SIZE,
    xOffset: MARGIN_SIZE,
    yOffset: MARGIN_SIZE,
  });

  const usersListMap = toListMap(users, 'id');
  tileCoordinates.forEach(item => {
    const userId = item.userId || '';
    const isMe = !!myId && myId === userId;
    drawTile(ctx, item, usersListMap[userId], isMe);
  });
}

export function getTileDimensions({
  count,
  contentHeight,
  contentWidth,
  gutterSize,
}: IGetTileDimensions) {
  const {
    bestColSize: columns,
    bestRowSize: rows,
    itemHeight,
  } = Grid.calcLayout(
    contentWidth,
    contentHeight,
    count,
    VIDEO_IDEAL_ASPECT_RATIO,
    VIDEO_MAX_ASPECT_RATIO,
  );

  const lateralGutterAllowance = ((columns - 1) * gutterSize) / columns;
  const verticalGutterAllowance = ((rows - 1) * gutterSize) / rows;
  const tileIdealHeight = itemHeight - verticalGutterAllowance;
  const tileMaxHeight = contentHeight / rows - verticalGutterAllowance;
  const tileProjectedHeight = Math.round(
    Math.min(tileIdealHeight, tileMaxHeight),
  );
  const tileMaxWidth = contentWidth / columns - lateralGutterAllowance;
  const tileIdealWidth = Math.floor(
    tileProjectedHeight * VIDEO_IDEAL_ASPECT_RATIO,
  );
  const tileWidth = Math.round(Math.min(tileIdealWidth, tileMaxWidth));
  const tileMaxHeightForAspectRatio = tileWidth / GROUP_PHOTO_MIN_ASPECT_RATIO;
  const tileHeight = Math.min(tileProjectedHeight, tileMaxHeightForAspectRatio);
  const matrixWidth = columns * (tileWidth + lateralGutterAllowance);
  const matrixHeight = rows * (tileHeight + verticalGutterAllowance);

  return {
    columns,
    rows,
    matrixWidth,
    matrixHeight,
    tileWidth,
    tileHeight,
  };
}

interface IGetTileCoordinates {
  users: IUser[];
  videos: HTMLVideoElement[];
  tileWidth: number;
  tileHeight: number;
  rows: number;
  columns: number;
  gutterSize: number;
  xOffset: number;
  yOffset: number;
}
export function getTileCoordinates({
  users,
  videos,
  tileWidth,
  tileHeight,
  rows,
  columns,
  gutterSize,
  xOffset,
  yOffset,
}: IGetTileCoordinates): TileCoordinates[] {
  const videosByUserId = videos.reduce(
    (memo: Record<string, HTMLVideoElement>, video: HTMLVideoElement) => {
      const userId = video?.dataset.userId || '';
      if (userId) {
        return {
          ...memo,
          [userId]: video,
        };
      }
      return memo;
    },
    {},
  );

  return users.reduce((memo: TileCoordinates[], user: IUser, index: number) => {
    const rowIndex = Grid.getRowIndex(index, columns, rows);
    const colIndex = Grid.getColumnIndex(index, columns, rows);
    const [x, y] = Grid.getCellOrigin(
      rowIndex,
      colIndex,
      tileHeight,
      tileWidth,
      gutterSize,
    );

    const source = videosByUserId[user.id];
    const tileCoordinates: TileCoordinates = {
      source,
      sourceWidth: source?.videoWidth || 0,
      sourceHeight: source?.videoHeight || 0,
      x: x + xOffset,
      y: y + yOffset,
      width: tileWidth,
      height: tileHeight,
      userId: user.id,
    };
    return memo.concat(tileCoordinates);
  }, []);
}

export function getScaleFactor(
  sourceWidth: number,
  sourceHeight: number,
  tileWidth: number,
  tileHeight: number,
) {
  const sourceAspectRatio = sourceWidth / sourceHeight;
  const tileAspectRatio = tileWidth / tileHeight;
  const constrainByWidth = tileAspectRatio >= sourceAspectRatio;
  return constrainByWidth ? sourceWidth / tileWidth : sourceHeight / tileHeight;
}

export function drawTile(
  ctx: CanvasRenderingContext2D,
  tileCoordinates: TileCoordinates,
  user?: IUser,
  isMe?: boolean,
) {
  const {
    source,
    sourceWidth,
    sourceHeight,
    x,
    y,
    width,
    height,
  } = tileCoordinates;

  const scaleFactor = getScaleFactor(sourceWidth, sourceHeight, width, height);
  const adjustedSourceWidth = width * scaleFactor;
  const adjustedSourceHeight = height * scaleFactor;

  ctx.fillStyle = COLOR_SHADE_80;
  ctx.fillRect(x, y, width, height);
  if (source) {
    ctx.drawImage(
      source,
      0,
      0,
      adjustedSourceWidth,
      adjustedSourceHeight,
      x,
      y,
      width,
      height,
    );
  }
  drawFrame(ctx, x, y, width, height, isMe ? COLOR_ENGAGEMENT : COLOR_WHITE);
  drawNameplate(ctx, x, y, width, height, user);
}

function drawFrame(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  color = 'white',
) {
  const radius = 8;
  ctx.fillStyle = 'transparent';
  ctx.strokeStyle = color;
  ctx.lineWidth = radius / 2;

  drawRoundedRectangle(ctx, x, y, width, height, radius);
}

function getNameplateCopy(str = 'Anonymous'): string {
  if (str.length > 30) {
    return `${str.slice(0, 30).trim()}...`;
  }
  return str;
}

function getNameplateWidth(chars: number): number {
  const widthForText = chars * 0.3 * GUTTER_SIZE + NAMEPLATE_MIN_WIDTH;
  return Math.min(widthForText, NAMEPLATE_MAX_WIDTH);
}

function drawNameplate(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  user?: IUser,
) {
  const margin = GUTTER_SIZE / 2;
  const radius = 4;
  ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
  ctx.strokeStyle = 'transparent';
  ctx.lineWidth = 0;

  const nameplateCopy = getNameplateCopy(user?.name);
  const nameplateWidth = getNameplateWidth(nameplateCopy.length);
  const nameplateX = x + width - margin - nameplateWidth;
  const nameplateY = y + height - margin - NAMEPLATE_HEIGHT;
  const nameplateContentWidth = nameplateWidth - 2 * margin;

  drawRoundedRectangle(
    ctx,
    nameplateX,
    nameplateY,
    nameplateWidth,
    NAMEPLATE_HEIGHT,
    radius,
  );

  ctx.fillStyle = COLOR_SHADE_80;
  ctx.font = NAMEPLATE_FONT;
  ctx.fillText(
    nameplateCopy,
    nameplateX + 1.5 * margin + NAMEPLATE_ICON_SIZE,
    nameplateY + 1.75 * margin,
    nameplateContentWidth,
  );

  ctx.fillStyle = 'rgba(0, 0, 0, 0.35)';
  drawTriangle(
    ctx,
    nameplateX + 0.875 * margin,
    nameplateY + NAMEPLATE_ICON_SIZE + 0.675 * margin,
    NAMEPLATE_ICON_SIZE,
    NAMEPLATE_ICON_SIZE,
  );
}

function drawRoundedRectangle(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: number,
) {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.stroke();
  ctx.fill();
  ctx.closePath();
}

function drawTriangle(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
) {
  ctx.beginPath();
  ctx.moveTo(x, y);
  ctx.lineTo(x + width, y);
  ctx.lineTo(x + width, y - height);
  ctx.lineTo(x, y);
  ctx.stroke();
  ctx.fill();
  ctx.closePath();
}

interface ICaptureHuddle {
  groupPhoto: IGroupPhoto;
  floor: IFloor | null;
  users: IUser[];
  debug?: boolean;
}
export async function captureHuddle({
  groupPhoto,
  floor,
  users,
  debug,
}: ICaptureHuddle): Promise<IGroupPhoto> {
  try {
    console.debug('[utils/groupPhoto] capturing huddle in a group photo...');
    const userId = groupPhoto.photographerId;

    let canvas: HTMLCanvasElement;
    if (debug) {
      canvas = document.getElementById(
        CANVASES.GroupPhoto,
      ) as HTMLCanvasElement;
    } else {
      canvas = document.createElement('CANVAS') as HTMLCanvasElement;
    }

    const canvasWidth = maxContentWidth;
    const canvasHeight = maxContentHeight;
    const videoGridWidth = Math.ceil((2 / 3) * canvasWidth - 2 * MARGIN_SIZE);
    const videoGridHeight = Math.round(canvasHeight - 2 * MARGIN_SIZE);
    const mapHeight = 0.4 * canvasHeight;

    canvas.width = canvasWidth;
    canvas.height = canvasHeight;
    canvas.style.backgroundColor = COLOR_WHITE;

    const ctx = canvas.getContext('2d');
    if (!ctx) throw new Error('ctx cannot be empty');

    ctx.fillStyle = COLOR_WHITE;
    ctx.fillRect(0, 0, canvasWidth, canvasHeight);

    if (floor?.mapUrl) {
      console.debug('[utils/groupPhoto] attempt to render map');
      await drawMap(
        ctx,
        floor.mapUrl,
        floor?.name,
        canvasWidth,
        canvasHeight,
        mapHeight,
        GUTTER_SIZE,
      );
      console.debug('[utils/groupPhoto] map rendered');
    } else {
      console.debug('[utils/groupPhoto] no map provided, skipping');
    }

    drawVideoTiles(
      ctx,
      canvasHeight,
      videoGridWidth,
      videoGridHeight,
      users,
      userId,
    );
    await drawLogo(ctx, canvasWidth);

    const output = canvas.toDataURL('image/png');
    console.debug('[utils/groupPhoto] success');

    return {
      ...groupPhoto,
      output,
      status: GroupPhotoStatus.FULFILLED,
    };
  } catch (e) {
    console.warn('[utils/groupPhoto] error:', e);
    return {
      ...groupPhoto,
      status: GroupPhotoStatus.REJECTED,
    };
  }
}

import { Snapshot } from '../';

export const MAP_WIDTH = 400;
export const MAP_HEIGHT = 250;

export const ELEVATOR_WIDTH = 200;
export const ELEVATOR_HEIGHT = 30;

export const EXIT_WIDTH = 80;
export const EXIT_HEIGHT = 30;

/** the unit of spawning grid, ideally a divisor of both map width and height */
export const GRID_UNIT = 10;

export const DEFAULT_SPAWNING_POINT = { x: GRID_UNIT, y: GRID_UNIT };

export const HUDDLE_CAPACITY = 16;

/** if a user has been inactive for more than this period, it will spawn to a
 * new location when activated */
export const RESPAWN_TIMEOUT = 10000;

export const DEFAULT_SNAPSHOT: Snapshot = {
  users: {},
  huddles: {},
  broadcasts: {},
  timestamp: Date.now(),
};

export const MAX_SPEAKERS = 16;

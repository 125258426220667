import { useEffect, useMemo, useState } from 'react';
import { MockSocket } from '../MockSocket';
import { createMockHuddle, createMockUser } from '../mocks';
import {
  Action,
  getDistance,
  getHuddleRadius,
  IHuddle,
  IUser,
} from '@poormanvr/common';

export function useMockSocket(defaultMe: IUser) {
  const socket = useMemo(() => new MockSocket(), []);
  const [socketOpened, setSocketOpened] = useState(false);

  useEffect(() => {
    const handleOpen = () => {
      setSocketOpened(true);
    };
    const handleClose = () => {
      setSocketOpened(false);
    };
    socket.on('open', handleOpen);
    socket.on('close', handleClose);
    return () => {
      socket.off('open', handleOpen);
      socket.off('close', handleClose);
    };
  }, [socket]);

  const [me, setMe] = useState<IUser>(defaultMe);
  const [them, setThem] = useState<IUser>(() =>
    createMockUser({ floorId: defaultMe.floorId }),
  );
  const [huddle, setHuddle] = useState<IHuddle | null>(null);

  useEffect(() => {
    const { server } = socket;

    const startMoving = ({ point }: Action.StartMoving) => {
      setMe({ ...me, point, moving: true });
    };
    const stopMoving = () => {
      setMe({ ...me, moving: false });
    };
    const raiseHand = ({ handRaised }: Action.RaiseHand) => {
      setMe({
        ...me,
        handRaised: handRaised ? { raisedAt: Date.now() } : null,
      });
    };
    const changeFloor = ({ floorId }: Action.ChangeFloor) => {
      setMe({ ...me, floorId });
    };
    const lockHuddle = ({ locked }: Action.LockHuddle) => {
      huddle && setHuddle({ ...huddle, locked });
    };

    server.on('START_MOVING', startMoving);
    server.on('STOP_MOVING', stopMoving);
    server.on('RAISE_HAND', raiseHand);
    server.on('CHANGE_FLOOR', changeFloor);
    server.on('LOCK_HUDDLE', lockHuddle);

    return () => {
      server.off('START_MOVING', startMoving);
      server.off('STOP_MOVING', stopMoving);
      server.off('RAISE_HAND', raiseHand);
      server.off('CHANGE_FLOOR', changeFloor);
      server.off('LOCK_HUDDLE', lockHuddle);
    };
  }, [socket, me, them, huddle]);

  useEffect(() => {
    const inHuddle =
      me.floorId === them.floorId &&
      getDistance(me.point, them.point) < getHuddleRadius(2);
    if (!me.moving && inHuddle && !huddle) {
      const newHuddle = createMockHuddle(me, them);
      setHuddle(newHuddle);
      setMe({ ...me, huddleId: newHuddle.id });
      setThem({ ...them, huddleId: newHuddle.id });
    } else if (!inHuddle && huddle) {
      setHuddle(null);
      setMe({ ...me, huddleId: null });
      setThem({ ...them, huddleId: null });
    }
  }, [me, them, huddle]);

  useEffect(() => {
    if (!socketOpened) return;
    socket.receiveSnapshot({
      users: {
        [me.id]: me,
        [them.id]: them,
      },
      broadcasts: {},
      huddles: huddle
        ? {
            [huddle.id]: huddle,
          }
        : {},
      timestamp: Date.now(),
    });
  }, [socket, socketOpened, me, them, huddle]);

  return { socket, me, them, huddle };
}

import React, {
  createContext,
  Dispatch,
  ReactNode,
  useMemo,
  useState,
} from 'react';
import { IUser } from '@poormanvr/common';

interface Value {
  isInTutorial: boolean;
  tutorialUser: IUser | null;
  setTutorialUser: Dispatch<IUser | null>;
  stepIndex: number;
  setStepIndex: Dispatch<number>;
}

export const TutorialContext = createContext<Value>(null as any);

interface Props {
  children: ReactNode;
}

export function TutorialProvider({ children }: Props) {
  const [tutorialUser, setTutorialUser] = useState<IUser | null>(null);
  const [stepIndex, setStepIndex] = useState(-1);

  const isInTutorial = Boolean(tutorialUser);

  const value = useMemo<Value>(
    () => ({
      isInTutorial,
      tutorialUser,
      setTutorialUser,
      stepIndex,
      setStepIndex,
    }),
    [isInTutorial, tutorialUser, stepIndex],
  );

  return (
    <TutorialContext.Provider value={value}>
      {children}
    </TutorialContext.Provider>
  );
}

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FastForwardRounded } from '@material-ui/icons';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { c } from '../../utils';
import { ANIM_DURATION } from '../../styles';
import logoSparkle from '../../assets/logo_sparkle.gif';
import { Clickable } from '../../components/Clickable';
import { Button } from '../../components/Button';
import { BuildingContext } from '../../contexts/BuildingContext';
import { TutorialContext } from '../../contexts/TutorialContext';
import { useMockSocket } from '../../hooks/useMockSocket';
import classes from './Tutorial.module.scss';
import { STEPS, TutorialState } from './steps';
import { DEFAULT_SPAWNING_POINT, IUser, Point } from '@poormanvr/common';

const MainPage = React.lazy(() => import('../MainPage'));

interface Props {
  user: IUser;
  onSubmit: () => void;
}

export function Tutorial({ user, onSubmit }: Props) {
  const { building, floors } = useContext(BuildingContext);
  const { stepIndex, setStepIndex } = useContext(TutorialContext);
  const { socket, me, them } = useMockSocket({
    ...user,
    point: DEFAULT_SPAWNING_POINT,
    active: true,
  });

  const [point, setPoint] = useState<Point | null>(null);

  const [elevatorOpened, setElevatorOpened] = useState(false);
  const [brochureOpened, setBrochureOpened] = useState(false);
  useEffect(() => {
    let isMounted = true;
    const loop = () => {
      if (!isMounted) return;
      const elevatorOpened = Boolean(document.querySelector('#elevator'));
      const brochureOpened = Boolean(document.querySelector('#brochure'));
      setElevatorOpened(elevatorOpened);
      setBrochureOpened(brochureOpened);
      window.setTimeout(loop, 200);
    };
    loop();
    return () => {
      isMounted = false;
    };
  }, []);

  const destinationFloor = useMemo(
    () =>
      Object.values(floors).find(floor => floor.id !== user.floorId) ?? null,
    [floors, user],
  );

  const tutorialState = useMemo<TutorialState>(
    () => ({
      building,
      floors,
      me,
      them,
      destinationFloor,
      elevatorOpened,
      brochureOpened,
    }),
    [
      building,
      floors,
      me,
      them,
      destinationFloor,
      elevatorOpened,
      brochureOpened,
    ],
  );

  useEffect(() => {
    if (stepIndex < 0) return;
    let newStepIndex = STEPS.findIndex((step, i) => {
      if (i < stepIndex) return false;
      if (!step.done) return true;
      return !step.done(tutorialState);
    });
    if (newStepIndex < 0) {
      setStepIndex(STEPS.length);
      return;
    }
    if (newStepIndex > 0) {
      const step = STEPS[newStepIndex];
      const prevStep = STEPS[newStepIndex - 1];
      if (step.dependent && !prevStep.done?.(tutorialState)) {
        newStepIndex--;
      }
    }
    setStepIndex(newStepIndex);
  }, [stepIndex, tutorialState, setStepIndex]);

  const step = stepIndex < STEPS.length ? STEPS[stepIndex] : null;

  useEffect(() => {
    if (!step) return;
    let isMounted = true;
    const loop = () => {
      if (!isMounted) return;
      const element = document.querySelector(step.selector);
      if (element) {
        const { top, bottom, left, right } = element.getBoundingClientRect();
        const centerX = (left + right) / 2;
        const centerY = (top + bottom) / 2;
        const point = step.point
          ? step.point({ top, bottom, left, right, centerX, centerY })
          : { x: centerX, y: centerY };
        setPoint(point);
      }
      window.setTimeout(loop, 200);
    };
    loop();
    return () => {
      isMounted = false;
    };
  }, [stepIndex, step, setStepIndex]);

  const nextStep = useCallback(() => {
    setStepIndex(stepIndex + 1);
  }, [setStepIndex, stepIndex]);

  const stepText = useMemo(() => {
    if (!step) return '';
    if (typeof step.text === 'function') {
      return step.text(tutorialState);
    }
    return step.text;
  }, [tutorialState, step]);

  return (
    <div className={classes.Tutorial}>
      <div className={classes.banner}>
        <div className={classes.text}>
          This is a tutorial. Complete the steps to enter the event.
        </div>
        <Button
          className={classes.skip}
          onClick={onSubmit}
          data-cy="skip-tutorial-button"
        >
          <FastForwardRounded className={classes.icon} />
          Skip tutorial
        </Button>
      </div>
      <div className={classes.content}>
        <MainPage className={classes.main} socket={socket} />
        {step && point && (
          <SwitchTransition>
            <CSSTransition
              key={stepIndex}
              timeout={ANIM_DURATION}
              unmountOnExit
              classNames={classes}
            >
              <div
                className={classes.cardWrapper}
                style={{ left: point.x, top: point.y }}
              >
                <div className={c(classes.card, classes[step.cardDirection])}>
                  <div
                    className={classes.text}
                    dangerouslySetInnerHTML={{ __html: stepText }}
                  />
                  {!step.done && (
                    <Button
                      className={classes.ok}
                      onClick={nextStep}
                      data-cy={step.dataCy}
                    >
                      OK!
                    </Button>
                  )}
                </div>
              </div>
            </CSSTransition>
          </SwitchTransition>
        )}
        {step && point && (
          <div
            className={classes.pointer}
            style={{ left: point.x, top: point.y }}
          />
        )}
        {stepIndex < 0 && (
          <div className={classes.popup}>
            <img className={classes.logo} src={logoSparkle} alt="logo" />
            <div className={classes.welcome}>Welcome {me.name}!</div>
            <div className={classes.description}>
              Click below to start the interactive tutorial that will show you
              how to navigate Gatherly.
            </div>
            <Button
              data-cy="start-tutorial-button"
              className={classes.proceed}
              primary
              onClick={nextStep}
            >
              Start tutorial!
            </Button>
            <Clickable
              data-cy="skip-tutorial-small-button"
              className={classes.skip}
              onClick={onSubmit}
            >
              Skip tutorial
            </Clickable>
          </div>
        )}
        {stepIndex === STEPS.length && (
          <div className={classes.popup}>
            <img className={classes.logo} src={logoSparkle} alt="logo" />
            <div className={classes.description}>
              You've completed the tutorial. Click below to enter the real
              event!
            </div>
            <Button
              data-cy="finish-tutorial-button"
              className={classes.proceed}
              primary
              onClick={onSubmit}
            >
              Let's go!
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

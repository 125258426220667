import browserNotSupported from './assets/browser_not_supported.png';
import deviceNotSupportedMobile from './assets/device_not_supported_mobile.png';
import deviceNotSupportedTablet from './assets/device_not_supported_tablet.png';
import logoFull from './assets/logo_full.png';
import sentimentSatisfied from './assets/sentiment_satisfied.svg';
import sentimentNeutral from './assets/sentiment_neutral.svg';
import sentimentDissatisfied from './assets/sentiment_dissatisfied.svg';

export const Images = {
  BROWSER_NOT_SUPPORTED: {
    src: browserNotSupported,
    alt: 'A cartoon browser with a sad face',
  },
  DEVICE_NOT_SUPPORTED_MOBILE: {
    src: deviceNotSupportedMobile,
    alt: 'A cartoon smartphone with a sad face',
  },
  DEVICE_NOT_SUPPORTED_TABLET: {
    src: deviceNotSupportedTablet,
    alt: 'A cartoon tablet with a sad face',
  },
  LOGO_FULL: {
    src: logoFull,
    alt: 'Gatherly logo',
  },
  SENTIMENT_DISSATISFIED: {
    src: sentimentDissatisfied,
    alt: 'Unsatisfactory',
  },
  SENTIMENT_NEUTRAL: {
    src: sentimentNeutral,
    alt: 'Ok',
  },
  SENTIMENT_SATISFIED: {
    src: sentimentSatisfied,
    alt: 'Satisfactory',
  },
};

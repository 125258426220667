import React, { ReactNode } from 'react';

import { ThemeProvider } from '../../contexts/ThemeContext';
import { DeviceProvider } from '../../contexts/DeviceContext';
import { BuildingProvider } from '../../contexts/BuildingContext';
import { TutorialProvider } from '../../contexts/TutorialContext';

interface Props {
  children: ReactNode;
}

export function GlobalProvider({ children }: Props) {
  return (
    <ThemeProvider>
      <DeviceProvider>
        <BuildingProvider>
          <TutorialProvider>{children}</TutorialProvider>
        </BuildingProvider>
      </DeviceProvider>
    </ThemeProvider>
  );
}

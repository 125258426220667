import {
  AgoraSession,
  ChatScope,
  ChimeSession,
  SnapshotDiff,
  IGroupPhoto,
  GroupPhotoStatus,
} from '../interfaces';

import { Message } from '..';

export enum Type {
  SNAPSHOT,
  SNAPSHOT_DIFF,
  MESSAGE,
  MESSAGE_DELETED,
  DIRECT_MESSAGE,
  DIRECT_MESSAGE_DELETED,
  HUDDLE_SESSION,
  BROADCAST_SESSION,
  PONG,
  SPEED_TEST_RESULT,
  AUDIO_MUTED_BY_PARTICIPANT,
  SCHEDULE_GROUP_PHOTO,
  CANCEL_GROUP_PHOTO,
  TAKE_GROUP_PHOTO,
}

export interface Mapping extends Record<Type, any> {
  [Type.SNAPSHOT]: SnapshotUpdated;
  [Type.SNAPSHOT_DIFF]: SnapshotDiffReceived;
  [Type.MESSAGE]: MessageReceived;
  [Type.MESSAGE_DELETED]: MessageDeleted;
  [Type.DIRECT_MESSAGE]: DirectMessageReceived;
  [Type.DIRECT_MESSAGE_DELETED]: DirectMessageDeleted;
  [Type.HUDDLE_SESSION]: HuddleSessionReceived;
  [Type.BROADCAST_SESSION]: BroadcastSessionReceived;
  [Type.PONG]: PongEmitted;
  [Type.AUDIO_MUTED_BY_PARTICIPANT]: MuteUser;
  [Type.SCHEDULE_GROUP_PHOTO]: GroupPhotoScheduled;
  [Type.CANCEL_GROUP_PHOTO]: GroupPhotoCanceled;
  [Type.TAKE_GROUP_PHOTO]: GroupPhotoTaken;
}

export interface MuteUser {}

export interface SnapshotUpdated {
  rawSnapshot: string;
  snapshotIndex: number;
}

export interface SnapshotDiffReceived {
  snapshotDiff: SnapshotDiff;
  snapshotIndex: number;
}

export interface MessageReceived {
  scope: ChatScope;
  targetId: string;
  message: Message;
}
export interface MessageDeleted {
  id: string;
  scope: ChatScope;
  targetId: string;
}

export interface DirectMessageReceived {
  scope: ChatScope;
  senderId: string;
  targetId: string;
  message: Message;
}
export interface DirectMessageDeleted {
  id: string;
  senderId: string;
  scope: ChatScope;
  targetId: string;
}

export interface HuddleSessionReceived {
  huddleId: string;
  chimeSession: ChimeSession | null;
}

export interface BroadcastSessionReceived {
  broadcastId: string;
  agoraSession: AgoraSession | null;
}

export interface PongEmitted {
  start: number;
}

export interface GroupPhotoScheduled {
  groupPhoto: IGroupPhoto;
}

export interface GroupPhotoCanceled {
  id: string;
}
export interface GroupPhotoTaken {
  id: string;
  status: GroupPhotoStatus;
}

import React, {
  FormEventHandler,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import peopleShuffle from '../../assets/people_shuffle.gif';
import { useRequest } from '../../hooks/useRequest';
import { getUserParams, handleRequestError, EventTracker } from '../../utils';
import { ANIM_DURATION } from '../../styles';
import { TextInput } from '../../components/TextInput';
import { Button } from '../../components/Button';
import { BuildingContext } from '../../contexts/BuildingContext';
import { TutorialContext } from '../../contexts/TutorialContext';
import classes from './ProfileSetup.module.scss';
import { HttpMethod, IUser, Request, EmailSettings } from '@poormanvr/common';

export function ProfileSetup() {
  const {
    building,
    floors,
    chimeMediaRegion,
    setMyId,
    blankEmailField,
  } = useContext(BuildingContext);
  const { setTutorialUser } = useContext(TutorialContext);

  const userParams = useMemo(() => getUserParams(floors), [floors]);

  const [user, setUser] = useState<IUser | null>(null);
  const [password, setPassword] = useState('');
  const [name, setName] = useState(userParams.name);
  const [title, setTitle] = useState(userParams.title);
  const [link, setLink] = useState('');
  const [email, setEmail] = useState('');
  // eslint-disable-next-line unused-imports/no-unused-vars-ts
  const [fields, setFields] = useState([]); // TODO: fields

  const createUser = useRequest<Request.CreateUser>(
    HttpMethod.POST,
    '/api/user',
  );
  const updateProfile = useRequest<Request.UpdateProfile>(
    HttpMethod.PUT,
    '/api/user/profile',
  );

  const handleSubmitBasic: FormEventHandler = useCallback(
    e => {
      e.preventDefault();
      if (!chimeMediaRegion) return;

      createUser({
        ...userParams,
        password,
        name,
        title,
        link: '',
        email: email || blankEmailField,
        fields: [],
        chimeMediaRegion,
        href: window.location.href,
      })
        .then(({ user }) => {
          setUser(user);
          EventTracker.identify(user, building);
        })
        .catch(handleRequestError);
    },
    [
      chimeMediaRegion,
      createUser,
      userParams,
      password,
      name,
      title,
      email,
      blankEmailField,
      building,
    ],
  );

  const handleSubmitAdditional: FormEventHandler = useCallback(
    e => {
      e.preventDefault();
      if (!user) return;
      updateProfile({
        name: user.name,
        title,
        link,
        email: email || blankEmailField,
        fields, // TODO: fields
      })
        .then(({ user }) => {
          setMyId(user.id);
          setTutorialUser(user);
        })
        .catch(handleRequestError);
    },
    [
      user,
      updateProfile,
      title,
      link,
      email,
      blankEmailField,
      fields,
      setMyId,
      setTutorialUser,
    ],
  );

  const basicProfileSetup = useMemo(
    () => (
      <div className={classes.setup}>
        <div data-cy="header" className={classes.welcome}>
          Welcome to <span className={classes.fancy}>Gatherly</span>!
        </div>
        <div className={classes.instructions}>
          Fill out the following to join the event.
        </div>
        <div className={classes.terms}>
          Gatherly requires access to your webcam and microphone. By clicking
          continue, you agree to our{' '}
          <a
            href="https://www.gatherly.io/legal/terms-of-use"
            rel="noreferrer"
            target="_blank"
            data-cy="terms-of-use"
          >
            Terms of Use
          </a>{' '}
          and{' '}
          <a
            href="https://www.gatherly.io/legal/privacy-policy"
            rel="noreferrer"
            target="_blank"
            data-cy="privacy-policy"
          >
            Privacy Policy
          </a>
          .
        </div>
        <form className={classes.form} onSubmit={handleSubmitBasic}>
          <TextInput
            label="Name"
            data-cy="name-input"
            value={name}
            required
            onChange={e => setName(e.target.value)}
            autoComplete="name"
          />
          {building?.passwordProtected && (
            <TextInput
              label="Password"
              data-cy="password-input"
              type="password"
              value={password}
              required
              onChange={e => setPassword(e.target.value)}
            />
          )}
          {building?.emailSettings !== EmailSettings.DISABLED && (
            <TextInput
              label="Email"
              data-cy="email-input"
              value={email}
              required={building?.emailSettings === EmailSettings.REQUIRED}
              onChange={e => setEmail(e.target.value)}
            />
          )}
          <Button primary type="submit" data-cy="submit">
            Continue
          </Button>
        </form>
      </div>
    ),
    [handleSubmitBasic, name, email, building, password],
  );

  const additionalProfileSetup = useMemo(
    () => (
      <div className={classes.setup}>
        <div className={classes.welcome}>
          More about <span className={classes.fancy}>You</span>
        </div>
        <div className={classes.instructions}>
          This information will be visible to others attending the event. You
          can edit this later in settings.
        </div>
        <form className={classes.form} onSubmit={handleSubmitAdditional}>
          <TextInput
            label="Title"
            data-cy="title-input"
            placeholder="Ex: Sales Rep"
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
          <TextInput
            label="Personal info link"
            placeholder="Ex: LinkedIn profile"
            data-cy="link-input"
            value={link}
            onChange={e => setLink(e.target.value)}
          />
          <Button primary type="submit" data-cy="additional-submit">
            {title || link ? 'Continue' : 'Skip'}
          </Button>
        </form>
      </div>
    ),
    [handleSubmitAdditional, title, link],
  );

  return (
    <div className={classes.ProfileSetup}>
      <div className={classes.content}>
        <div className={classes.logo}>
          <div className={classes.logoImage}>
            <img src={peopleShuffle} alt="logo" />
          </div>
        </div>
        <SwitchTransition>
          <CSSTransition
            key={user ? 'additional' : 'basic'}
            timeout={ANIM_DURATION}
            unmountOnExit
            classNames={classes}
          >
            {user ? additionalProfileSetup : basicProfileSetup}
          </CSSTransition>
        </SwitchTransition>
      </div>
    </div>
  );
}

export enum GroupPhotoStatus {
  IDLE = 'IDLE', // Scheduled
  PENDING = 'PENDING', // Rendering
  FULFILLED = 'FULFILLED', // Rendered
  REJECTED = 'REJECTED', // Rendering error
}
export interface IGroupPhoto {
  id: string;
  scheduledAt: number;
  scheduledFor: number;
  huddleId: string;
  photographerId: string;
  hasError?: boolean;
  status: GroupPhotoStatus;
  output?: string;
}
